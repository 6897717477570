import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const UkraineCrisisPage = () => (
    <Layout>
        <Seo title="Ukraine Crisis | Nemo Personal Finance" description={'If you\'re an exisitng customer with Nemo Personal Finance and have any issues don\'t hesitate to contact us. Visit us online for any assistance you need.'} lang={'en-gb'} />
        <div className="threeCol purple cfx">
        <div class="threeCol purple cfx">
        <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/support/extra-help-when-you-need-it">Extra help when you need it</a></li>
                        <li><a href="/support/homes-for-ukraine" className={'selected'}>Homes for Ukraine</a></li>
                    </ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>Ukraine Crisis</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <p className="leading">Important information for NEMO customers</p>
      
            
<p>The current events in Ukraine have shocked and saddened us all and, as an organisation, our thoughts are with the millions of people impacted by this awful conflict.</p>

<p>Any Nemo customers considering opening their homes to Ukraine refugees as part of the ‘Homes for Ukraine’ government scheme, and have been accepted as refugee sponsors, we ask that you let us, your mortgage provider and your insurance provider know when this is approved. Please also remain aware of the latest guidance being shared by the UK Government, via the <a target="_blank" rel="noreferrer" href="https://www.gov.uk/register-interest-homes-ukraine">Homes for Ukraine Sponsorship webpage</a>, which will be developing further over the next few weeks.</p>

<p>It’s important that all customers wanting to provide this support consider whether you are still able to afford your household bills, pay your mortgage and that it doesn’t put you in financial difficulty.</p>
                    </div>
                </div>
                <div class="fbCol">
                    <div class="fbList">
                        <ul>
                            <li class="">
                                <div class="top"></div>
                                <div class="txtWrap">
                                    <h3><span style={{'display': 'none'}}>&nbsp;</span></h3>
                                    <p>YOUR HOME MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON A MORTGAGE OR ANY OTHER DEBT SECURED ON IT.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
        </div>
    </Layout>
)

export default UkraineCrisisPage;